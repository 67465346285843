var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app_container"},[_c('header',[_c('ComHeader'),_vm._m(0),_c('div',{staticClass:"class_wrap _flex"},_vm._l((_vm.classTab),function(v,i){return _c('div',{key:i,class:['tabLi', _vm.tabId == v.id ? 'on' : ''],on:{"click":function($event){return _vm.changeTab(v)}}},[_vm._v(" "+_vm._s(v.name)+" ")])}),0)],1),_c('main',[_c('div',{staticClass:"crumbs _flex"},[_c('div',{staticClass:"text"},[_vm._v("网站首页")]),_c('i',{staticClass:"el-icon-arrow-right"}),_c('div',{staticClass:"text"},[_vm._v("关于我们")]),_c('i',{staticClass:"el-icon-arrow-right"}),_c('div',{staticClass:"text now"},[_vm._v(_vm._s(_vm.crumbsName))])]),_c('div',{staticClass:"us_content"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.crumbsName))]),(_vm.tabId == 1)?_c('div',{staticClass:"content_wrap"},[_vm._m(1),_vm._m(2)]):_vm._e(),(_vm.tabId == 2)?_c('div',{staticClass:"content_wrap"},[_vm._m(3),_vm._m(4)]):_vm._e(),(_vm.tabId == 3)?_c('div',{staticClass:"content_wrap"},[_vm._m(5)]):_vm._e()])]),_c('footer',[_c('ComFooter')],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner"},[_c('div',{staticClass:"tit"},[_vm._v("商城系统定制开发")]),_c('div',{staticClass:"know_btn _flex"},[_c('div',{staticClass:"text"},[_vm._v("立即咨询")]),_c('div',{staticClass:"icon _flex"},[_c('img',{attrs:{"src":require("../assets/homeImg/more.webp"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"intro"},[_c('p',[_vm._v(" 汇聚智慧，砥砺前行，共创美好明天！我们深知我们的代码承载着每一个客户的耀与梦想，365天7*24小时的售后支撑是我们最引以为傲的资本。畅冉企业信息咨询(西安)有限公司。我司成功帮助多家知名互联网公司获得多轮融资。专注于手机APP定制开限公司是由多位互联网龙头企业技术大牛于2023年创办，高端网站定制、企业网站建设、手机移动网站建设、应用系统开发等为企业提供全球化互联网解决方案。公司自创立以来，已成功为数百余家企事业单位、政府机关提供过高端互联网服务。 ")]),_c('p',[_vm._v(" 我们秉承“为合作伙伴创造价值”的核心价值观，以“诚实、宽容、创服务”为精神。 ")]),_c('p',[_vm._v("欢迎全国各地合作伙伴来公司洽谈，携手共进，共赢未来!")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"num_wrap _flex"},[_c('div',{staticClass:"num_li"},[_c('div',{staticClass:"num"},[_vm._v("4"),_c('span',[_vm._v("年")])]),_c('div',{staticClass:"text"},[_vm._v("互联网前沿技术经验")])]),_c('div',{staticClass:"num_li"},[_c('div',{staticClass:"num"},[_vm._v("200"),_c('span',[_vm._v("＋")])]),_c('div',{staticClass:"text"},[_vm._v("商标、专利、软件著作权")])]),_c('div',{staticClass:"num_li"},[_c('div',{staticClass:"num"},[_vm._v("360"),_c('span',[_vm._v("行")])]),_c('div',{staticClass:"text"},[_vm._v("全行业覆盖")])]),_c('div',{staticClass:"num_li"},[_c('div',{staticClass:"num"},[_vm._v("600"),_c('span',[_vm._v("＋")])]),_c('div',{staticClass:"text"},[_vm._v("品牌公司的认可")])]),_c('div',{staticClass:"num_li"},[_c('div',{staticClass:"num"},[_vm._v("1000"),_c('span',[_vm._v("行")])]),_c('div',{staticClass:"text"},[_vm._v("企业客户的选择")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"enterprise _flex"},[_c('div',{staticClass:"enter"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../assets/homeImg/like.webp"),"alt":""}})]),_c('div',{staticClass:"text"},[_vm._v("企业愿景")])]),_c('div',{staticClass:"enter"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../assets/homeImg/file.webp"),"alt":""}})]),_c('div',{staticClass:"text"},[_vm._v("企业使命")])]),_c('div',{staticClass:"enter"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../assets/homeImg/ours.webp"),"alt":""}})]),_c('div',{staticClass:"text"},[_vm._v("企业精神")])]),_c('div',{staticClass:"enter"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../assets/homeImg/notic.webp"),"alt":""}})]),_c('div',{staticClass:"text"},[_vm._v("企业口号")])]),_c('div',{staticClass:"enter _last"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../assets/homeImg/value.webp"),"alt":""}})]),_c('div',{staticClass:"text"},[_vm._v("企业价值观")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"enter_info _flex"},[_c('div',{staticClass:"info_li"},[_c('div',{staticClass:"tit"},[_vm._v("企业愿景")]),_c('div',{staticClass:"text"},[_c('p',[_vm._v("致力于帮助企业")]),_c('p',[_vm._v("实现互联网+")])])]),_c('div',{staticClass:"info_li"},[_c('div',{staticClass:"tit"},[_vm._v("企业使命")]),_c('div',{staticClass:"text"},[_vm._v("成就自己，为客户创造价值")])]),_c('div',{staticClass:"info_li"},[_c('div',{staticClass:"tit"},[_vm._v("企业精神")]),_c('div',{staticClass:"text"},[_c('p',[_vm._v("用真诚服务客户，")]),_c('p',[_vm._v("用品质成就自己")])])]),_c('div',{staticClass:"info_li"},[_c('div',{staticClass:"tit"},[_vm._v("企业口号")]),_c('div',{staticClass:"text"},[_c('p',[_vm._v("汇聚智慧，砥砺前行，")]),_c('p',[_vm._v("共创美好明天！")])])]),_c('div',{staticClass:"info_li _last"},[_c('div',{staticClass:"tit"},[_vm._v("企业价值观")]),_c('div',{staticClass:"text"},[_vm._v("诚信为先，服务至上")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cooperatePic"},[_c('img',{attrs:{"src":require("../assets/homeImg/cooperateLogo.webp"),"alt":""}})])
}]

export { render, staticRenderFns }