<!-- 底部组件 -->
<template>
  <div class="app_container">
    <!-- icon分类 -->
    <div class="top_wrap _flex">
      <div class="icon_wrap _flex" v-for="(v, i) in topData" :key="i">
        <div class="icon">
          <img :src="v.icon" alt="" />
        </div>
        <div class="text">{{ v.text }}</div>
      </div>
    </div>

    <!-- 服务列表 -->
    <div class="serve_wrap _flex">
      <div class="_l _flex">
        <div class="se_li">
          <div class="tit" @click="gopath('/case')">案例展示</div>
          <p
            v-for="(v, i) in projectLi"
            :key="i"
            @click="gopath('/case', v.id, v.name)"
          >
            {{ v.name }}
          </p>
          <p @click="gopath('/case', 123)">网站定制开发</p>
          <p @click="gopath('/case', 456)">app定制开发</p>
        </div>
        <div class="se_li">
          <div class="tit" @click="gopath('/about')">关于我们</div>
          <p @click="gopath('/about', 1)">公司简介</p>
          <p @click="gopath('/about', 2)">企业文化</p>
          <p @click="gopath('/about', 3)">合作伙伴</p>
        </div>
        <div class="se_li">
          <div class="tit" @click="gopath('/contact')">联系我们</div>
          <p @click="gopath('/contact', 0)">联系方式</p>
          <p @click="gopath('/contact', 1)">隐私政策</p>
          <p @click="gopath('/contact', 2)">法律声明</p>
        </div>
      </div>
      <div class="_r">
        <div class="call_wrap _flex">
          <div class="icon">
            <img src="../assets/homeImg/call.webp" alt="" />
          </div>
          <div class="num">15598241114 <span>(7*24h)</span></div>
        </div>
        <p>地 址:陕西省西安市高新区汇鑫ibc大厦A座2410</p>
        <p>E-mail: a15598241114@gmail.com</p>
        <p>网址:https://changran.dudupaidui.com/#/</p>
        <div class="pic_wrap _flex">
          <div class="icon">
            <img
              class="icon_img"
              :src="weUrl"
              alt=""
              @mouseover="headelMouseover('we')"
              @mouseout="headelMouseout('we')"
            />
            <img
              v-if="Ifwecode"
              class="code weCode"
              src="../assets/homeImg/footWechat.webp"
              alt=""
            />
          </div>
          <div class="icon">
            <img
              class="icon_img"
              :src="qqUrl"
              alt=""
              @mouseover="headelMouseover('qq')"
              @mouseout="headelMouseout('qq')"
            />
            <img
              v-if="Ifqqcode"
              class="code qqCode"
              src="../assets/homeImg/footqq.webp"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>

    <!-- 链接 -->
    <!-- <div class="link_wrap">
      <div class="link_con _flex">
        <div class="tit">友情链接：</div>
        <p>杭州软件开发</p>
        <p>海外仓对接系统</p>
        <p>河南招标网</p>
        <p>成都设计公司</p>
        <p>直销软件开发</p>
        <p>人才网站开发</p>
        <p>成都品牌设计公司</p>
        <p>商标分类</p>
        <p>品牌设计公司</p>
      </div>
      <div class="link_con _flex">
        <p>上海SEO</p>
        <p>高端网站建设</p>
        <p>空运货代</p>
        <p>航空运输公司</p>
        <p>郑州软件开发</p>
        <p>多多出评软件</p>
      </div>
    </div> -->

    <!-- 公司信息 -->
    <div class="info_wrap _flex">
      <div class="_l">
        <p>
          Copyright@2014-2024 畅冉企业信息咨询（西安）有限公司 All Rights
          Reserved. 陕ICP备2024037464号
        </p>
        <p>Processed in2.526512 second(s)</p>
      </div>
      <!-- <div class="_r">在线留言丨联系我们丨站点地图</div> -->
    </div>
  </div>
</template>

<script>
import { getClassify } from "../request/api";
export default {
  components: {},
  data() {
    return {
      topData: [
        {
          icon: require("../assets/homeImg/footIcon1.webp"),
          text: "7x24小时售后支持",
        },
        {
          icon: require("../assets/homeImg/footIcon2.webp"),
          text: "跨浏览器兼容",
        },
        {
          icon: require("../assets/homeImg/footIcon3.webp"),
          text: "符合W3C标准及规范",
        },
        {
          icon: require("../assets/homeImg/footIcon4.webp"),
          text: "100%纯手工编码",
        },
        {
          icon: require("../assets/homeImg/footIcon5.webp"),
          text: "免费ICP备案服务",
        },
      ],

      projectLi: [], //项目分类
      weUrl:require("../assets/homeImg/wechat.webp"),  //微信图标
      qqUrl:require("../assets/homeImg/qq.webp"),  //微信图标
      Ifwecode: false, //微信二维码弹窗
      Ifqqcode: false, //qq二维码弹窗
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.getClassData();
  },
  methods: {
    // 获取案例展示分类
    getClassData() {
      getClassify().then((res) => {
        this.projectLi = res.data.project; //项目分类
      });
    },

    // 案例跳转
    gopath(url, urlId, val) {      
      if (urlId!==undefined) {
        this.$router.push({
          path: url,
          query: { id: urlId, name: val },
        });
      } else {
        this.$router.push(url);
      }
    },

    // 鼠标移入  展示二维码弹窗
    headelMouseover(val) {
      if (val == "we") {
        this.Ifwecode = true;
        this.weUrl=require('../assets/homeImg/footWeOn.webp')
      }
      if (val == "qq") {
        this.Ifqqcode = true;
        this.qqUrl=require('../assets/homeImg/footqqOn.webp')
      }
    },

    // 移出
    headelMouseout(val) {
      if (val == "we") {
        this.Ifwecode = false;
        this.weUrl=require('../assets/homeImg/wechat.webp')
      }
      if (val == "qq") {
        this.Ifqqcode = false;
        this.qqUrl=require('../assets/homeImg/qq.webp')
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.app_container {
  background: #252a36;
  padding: 48px 204px 28px 204px;
  .top_wrap {
    padding: 46px 0;
    justify-content: space-between;
    border-bottom: 1px solid #474e5d;
    .icon_wrap {
      .icon {
        width: 40px;
        height: 40px;
        margin-right: 24px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .text {
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 21px;
        color: #ffffff;
      }
    }
  }

  // 服务列表
  .serve_wrap {
    padding: 43px 0 64px 0;
    border-bottom: 1px solid #474e5d;

    ._l {
      flex: 1;
      align-items: flex-start;
      padding-right: 338px;
      justify-content: space-between;
      border-right: 1px solid #474e5d;
      .se_li {
        font-family: PingFang SC, PingFang SC;
        .tit {
          font-weight: 600;
          font-size: 21px;
          color: #ffffff;
          cursor: pointer;
        }
        p {
          font-weight: 400;
          font-size: 19px;
          color: #d6d6d6;
          margin-top: 23px;
          cursor: pointer;
        }
      }
    }
    ._r {
      padding-left: 84px;
      padding-right: 28px;
      position: relative;
      .call_wrap {
        margin-bottom: 27px;
        .icon {
          width: 24px;
          height: 24px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .num {
          font-family: DIN, DIN;
          font-weight: 700;
          font-size: 37px;
          color: #ffffff;
          span {
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 19px;
            color: #d6d6d6;
          }
        }
      }
      p {
        max-width: 400px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 19px;
        color: #d6d6d6;
        line-height: 47px;
      }
      .pic_wrap {
        margin-top: 18px;
        .icon {
          .icon_img {
            width: 54px;
            height: 54px;
          }
        }
        .icon:nth-child(1) {
          margin-right: 32px;
        }
        .code {
          width: 142px;
          height: 148px;
          display: block;
          animation: fadeIn 0.5s ease-in-out forwards;
        }
        .weCode {
          position: absolute;
          top: 90px;
          left: 38px;
        }
        .qqCode {
          position: absolute;
          top: 90px;
          left: 126px;
        }
      }
    }
  }

  // 链接
  .link_wrap {
    padding: 32px 0 18px 0;
    border-bottom: 1px solid #474e5d;

    .link_con {
      font-family: PingFang SC, PingFang SC;
      .tit {
        font-weight: 600;
        font-size: 19px;
        color: #ffffff;
      }
      p {
        font-weight: 400;
        font-size: 19px;
        color: #d6d6d6;
        padding: 0 20px;
      }
    }
    .link_con:nth-child(2) {
      margin-left: 94px;
      margin-top: 20px;
    }
  }

  // 公司信息
  .info_wrap {
    padding-top: 30px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 19px;
    color: #d6d6d6;
    align-items: flex-start;
    justify-content: space-between;
  }

  // 弹窗动画 -- 缓慢进入
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}
</style>
