<!-- 案例展示 -->
<template>
  <div class="app_container">
    <header>
      <!-- 导航条 -->
      <ComHeader></ComHeader>
      <div class="banner">
        <div class="tit">商城系统定制开发</div>
        <div class="know_btn _flex">
          <div class="text">立即咨询</div>
          <div class="icon _flex">
            <img src="../assets/homeImg/more.webp" alt="" />
          </div>
        </div>
      </div>
      <!-- 项目分类 -->
      <div class="class_wrap _flex">
        <div
          :class="['tabLi', v.id == proId ? 'on' : '']"
          v-for="(v, i) in projectLi"
          :key="i"
          @click="changePro(v)"
        >
          {{ v.name }}
        </div>
      </div>
    </header>
    <main>
      <!-- 面包屑 -->
      <div class="crumbs _flex">
        <div class="text">网站首页</div>
        <i class="el-icon-arrow-right"></i>
        <div class="text">案例展示</div>
        <i class="el-icon-arrow-right"></i>
        <div class="text now">{{ proName }}</div>
      </div>

      <!-- 案例展示 -->
      <div class="case_wrap">
        <!-- 分类tab  行业分类-->
        <div class="tab_wrap _flex">
          <div class="_l">
            <div class="tit">行业分类</div>
            <div
              :class="['all', cateId == null ? 'active' : '']"
              @click="changeAll(1)"
            >
              全部
            </div>
          </div>
          <div class="tab _flex">
            <div
              :class="['item', cateId == v.id ? 'active' : '']"
              v-for="(v, i) in categoryLi"
              :key="i"
              @click="changeCate(v.id)"
            >
              {{ v.name }}
            </div>
          </div>
        </div>
        <!-- 颜色分类 -->
        <div class="tab_wrap _flex">
          <div class="_l">
            <div class="tit">颜色搭配</div>
            <div
              :class="['all', colorId == null ? 'active' : '']"
              @click="changeAll(2)"
            >
              全部
            </div>
          </div>
          <div class="tab _flex">
            <div
              :class="['item', colorId == v.id ? 'active' : '']"
              v-for="(v, i) in colourLi"
              :key="i"
              @click="changeColor(v.id)"
            >
              {{ v.name }}
            </div>
          </div>
        </div>
        <!-- 客户端 -->
        <div class="tab_wrap _flex">
          <div class="_l">
            <div class="tit">客户端</div>
            <div
              :class="['all', clientId == null ? 'active' : '']"
              @click="changeAll(3)"
            >
              全部
            </div>
          </div>
          <div class="tab _flex">
            <div
              :class="['item', clientId == v.id ? 'active' : '']"
              v-for="(v, i) in clientLi"
              :key="i"
              @click="changeClient(v.id)"
            >
              {{ v.name }}
            </div>
          </div>
        </div>

        <!-- 案例列表 -->
        <div class="classify_li _flex" v-if="caseList.length !== 0">
          <div
            class="list_wrap"
            v-for="(item, ind) in caseList"
            :key="ind"
            @click="handleToDetails(item.id)"
          >
            <div class="li_pic">
              <img :src="item.cases_image" alt="" />
            </div>
            <div class="li_tit">{{ item.name }}</div>
            <div class="li_message _flex">
              <div class="mes_time _flex">
                <div class="icon _flex">
                  <img src="../assets/homeImg/wait.webp" alt="" />
                </div>
                <div class="data">{{ timeTotal(item.createtime, true) }}</div>
              </div>
              <div class="label_wrap _flex">
                <div class="label _flex">
                  <div class="icon">
                    <img src="../assets/homeImg/flag.webp" alt="" />
                  </div>
                  <!-- <div class="text" v-for="(v, i) in item.categories" :key="i">
                    <span>{{ v.name }}</span>
                    <span>{{
                      i === item.categories.length - 1 ? "" : "."
                    }}</span>
                  </div> -->
                  <div class="text _flex">
                    <span>{{ item.categories[0].name }}</span>
                    <span v-if="item.categories.length > 1">…</span>
                  </div>
                </div>
                <div class="label _flex">
                  <div class="icon">
                    <img src="../assets/homeImg/canvas.webp" alt="" />
                  </div>
                  <div class="text _flex">
                    <span>{{ item.colours[0].name }}</span>
                    <span v-if="item.colours.length > 1">…</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="classify_empty" v-else>暂无数据</div>

        <!-- 分页 -->
        <div class="paging_wrap _flex" v-if="caseList.length !== 0">
          <el-pagination
            background
            layout="prev, pager, next"
            next-text="下一页"
            prev-text="上一页"
            :hide-on-single-page="false"
            :total="caseTotals"
            :page-size="pageSize"
            :current-page.sync="paramsPage"
            @current-change="currentChange"
            @size-change="sizeChange"
          />
        </div>
      </div>
    </main>
    <footer>
      <ComFooter></ComFooter>
    </footer>
  </div>
</template>

<script>
import ComHeader from "@/components/comHeader.vue";
import ComFooter from "@/components/comFooter.vue";
import { getClassify, getClassifyList } from "../request/api";
import { timeTotal } from "../utils/index";
export default {
  components: {
    ComHeader,
    ComFooter,
  },
  data() {
    return {
      timeTotal,
      projectLi: [], //项目分类
      proId: null, //项目分类ID
      proName: "", //项目名称
      categoryLi: [], //行业分类
      cateId: null, //行业ID
      colourLi: [], //颜色分类
      colorId: null, //颜色ID
      clientLi: [], //客户端分类
      clientId: null, //客户端ID
      paramsPage: 1, //当前页码
      pageSize: 9, //一页多少数据
      caseList: [], //案例列表
      caseTotals: null, //案例总条数
    };
  },
  watch: {
    $route: {
      handler(newV, _oldV) {
        if (newV) {
          this.proId = newV.query.id;
          if (newV.query.id) {
            this.proName = newV.query.name;
          } else {
            this.proName = "";
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {},
  created() {
    this.getData();
  },
  mounted() {
    // this.getClassData(); // 获取案例展示分类
    // this.getCaseData(); //获取案例列表
  },
  methods: {
    async getData() {
      await getClassify().then((res) => {
        this.projectLi = res.data.project; //项目分类
        this.proId = res.data.project[0].id;
        this.proName = res.data.project[0].name;
        //  数据结构变化--> 行业分类取二级数据
        this.categoryLi = res.data.project[0].categories;

        // this.categoryLi = res.data.category; //行业分类
        this.colourLi = res.data.colour; //颜色分类
        this.clientLi = res.data.client; //客户端分类
      });
      await getClassifyList({
        project_id: this.proId, //项目分类
        category_id: this.cateId, //行业分类
        colour_id: this.colorId, //颜色分类
        client_id: this.clientId, //客户端
        page: this.paramsPage,
        page_size: this.pageSize,
      }).then((res) => {
        this.caseList = res.data.cases;
        this.caseTotals = res.data.total;
      });
    },
    // // 获取案例展示分类
    // getClassData() {
    //   getClassify().then((res) => {
    //     this.projectLi = res.data.project; //项目分类
    //     this.categoryLi = res.data.category; //行业分类
    //     this.colourLi = res.data.colour; //颜色分类
    //     this.clientLi = res.data.client; //客户端分类
    //   });
    // },

    // 切换项目分类
    changePro(val) {
      this.proId = val.id;
      this.proName = val.name;

      // 数据结构变化 需根据项目分类筛选行业
      let newArr = [];
      newArr = this.projectLi.filter((v) => {
        if (v.id == val.id) {
          return v;
        }
      });
      this.categoryLi = newArr[0].categories;

      this.paramsPage = 1;
      this.getCaseData();
    },

    // 切换行业
    changeCate(ind) {
      this.cateId = ind;
      this.getCaseData();
    },

    // 切换颜色
    changeColor(ind) {
      this.colorId = ind;
      this.getCaseData();
    },

    // 切换客户端
    changeClient(ind) {
      this.clientId = ind;
      this.getCaseData();
    },

    changeAll(val) {
      switch (val) {
        case 1:
          this.cateId = null;
          break;
        case 2:
          this.colorId = null;
          break;
        case 3:
          this.clientId = null;
          break;
      }
      this.getCaseData();
    },

    // 获取案例列表
    getCaseData() {
      getClassifyList({
        project_id: this.proId,
        category_id: this.cateId,
        colour_id: this.colorId,
        client_id: this.clientId,
        page: this.paramsPage,
        page_size: this.pageSize,
      }).then((res) => {
        this.caseList = res.data.cases;
        this.caseTotals = res.data.total;
      });
    },

    // 页面切换
    currentChange(page) {
      this.paramsPage = page;
      this.getCaseData();
    },

    // 页码切换
    sizeChange(size) {
      this.pageSize = size;
      this.getCaseData();
    },

    // 跳转案例详情
    handleToDetails(id) {
      this.$router.push({
        path: "/caseDetails",
        query: { id: id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../style/comStyle.scss";
.app_container {
  header {
    .banner {
      @include _headStyle;
    }

    //   项目分类
    .class_wrap {
      @include _headClass;
    }
  }

  main {
    background: #f0f0f0;
    padding: 0 126px;
    padding-bottom: 126px;

    // 面包屑
    .crumbs {
      @include _crumbsStyle;
    }

    // 案例展示
    .case_wrap {
      background: #ffffff;
      padding: 56px 88px 90px;

      //   案例分类
      .tab_wrap {
        align-items: flex-start;
        font-family: PingFang SC, PingFang SC;
        margin-bottom: 66px;
        ._l {
          display: flex;
          align-items: flex-start;
          .tit {
            width: 85px;
            font-weight: 600;
            font-size: 21px;
            color: #151618;
            padding-top: 10px;
            text-align: right;
          }
          .all {
            font-weight: 400;
            font-size: 21px;
            padding: 10px 16px;
            margin-left: 66px;
            cursor: pointer;
          }
        }
        .active {
          border-radius: 5px 5px 5px 5px;
          border: 1px solid #7095f8;
          color: #7095f8;
        }
        .tab {
          flex: 1;
          flex-flow: wrap;
          font-weight: 400;
          font-size: 21px;
          color: #585858;
          cursor: pointer;
          .item {
            padding: 10px 40px;
          }
        }
      }

      // 案例列表
      .classify_li {
        @include _class;
      }

      // 案例列表--空
      .classify_empty {
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 27px;
        color: #878686;
        text-align: center;
        font-weight: 500;
      }

      //   分页
      .paging_wrap {
        justify-content: center;
        margin-top: 50px;

        ::v-deep .el-pager .number {
          border-radius: 5px 5px 5px 5px;
          border: 1px solid #7095f8;
          background: #ffffff;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #7095f8;
        }
        ::v-deep .el-pager .active {
          background: #7095f8;
          color: #fff;
        }
        ::v-deep .btn-next,
        ::v-deep .btn-prev {
          border-radius: 5px 5px 5px 5px;
          border: 1px solid #7095f8;
          color: #7095f8;
          background: #ffffff;
          padding: 0 10px;
        }
      }
    }
  }
}
</style>
