<!-- 头部导航条 -->
<template>
  <div class="app_container">
    <!-- 导航条 -->
    <div class="nav_wrap">
      <div class="nav_top _flex">
        <div class="_l _flex">
          <div class="logo">
            <img src="../assets/homeImg/logo.webp" alt="" />
          </div>
          <div class="logo_tit">汇聚智慧，砥砺前行，共创美好明天！</div>
        </div>
        <div class="_r _flex">
          <div class="search_wrap _flex">
            <input type="text" placeholder="搜索从这里开始…" />
            <div class="icon">
              <img src="../assets/homeImg/search.webp" alt="" />
            </div>
          </div>
          <!-- <div class="line_way">在线留言</div> -->
          <div class="line_way">联系方式</div>
        </div>
      </div>

      <div class="nav_bot _flex">
        <div class="_l _flex">
          <div class="tab" v-for="(v, i) in navLi" :key="i">
            <div
              :class="['tit', navId == v.id ? 'on' : '']"
              @mouseover="menuMouseover(v.id)"
              @click="goPath(v.url)"
            >
              {{ v.tit }}
            </div>
            <!-- 折叠菜单 -->
            <div
              class="menu_wrap"
              id="foldmenu"
              v-if="v.id !== 1 && v.id == navId"
            >
              <div
                @mouseout="menuMouseout()"
                class="menu_li"
                v-for="(item, ind) in v.navMenu"
                :key="ind"
                @click="menuPath(v.url, item.id, item.name)"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>

        <div class="_r _flex">
          <div class="line">【服务热线】</div>
          <div class="number _flex">
            <div class="icon">
              <img src="../assets/homeImg/phone.webp" alt="" />
            </div>
            <div class="num">15598241114</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getClassify } from "../request/api";
export default {
  components: {},
  data() {
    return {
      navLi: [
        {
          id: 1,
          tit: "网站首页",
          url: "/",
        },
        {
          id: 2,
          tit: "案例展示",
          url: "/case",
          navMenu: [
            { id: null, name: "网站定制开发" },
            { id: null, name: "app定制开发" },
          ],
        },
        {
          id: 3,
          tit: "关于我们",
          url: "/about",
          navMenu: [
            { id: 1, name: "公司简介" },
            { id: 2, name: "企业文化" },
            { id: 3, name: "合作伙伴" },
          ],
        },
        {
          id: 4,
          tit: "联系我们",
          url: "/contact",
          navMenu: [
            { id: 0, name: "联系我们" },
            { id: 1, name: "隐私政策" },
            { id: 2, name: "法律声明" },
          ],
        },
      ],
      navId: 1,
    };
  },
  computed: {},
  created() {},
  mounted() {
    // 获取案例展示分类
    getClassify().then((res) => {
      this.navLi[1].navMenu.push(...res.data.project); //项目分类)
    });
  },
  methods: {
    // 导航跳转路由
    goPath(url) {
      if (this.$route.path !== url) {
        this.$router.push(url);
      }
    },

    // 菜单跳转
    menuPath(url, urlId, val) {
      this.$router.push({
        path: url,
        query: { id: urlId, name: val },
      });
    },

    // 鼠标移入
    menuMouseover(id) {
      this.navId = id;
    },

    // 鼠标移出
    menuMouseout() {
      // console.log(this.$refs.foldmenu);
    },
  },
};
</script>

<style lang="scss" scoped>
// 导航条
.nav_wrap {
  width: 100%;
  position: absolute;
  color: #ffffff;
  // height: 127px;
  z-index: 9;
  .nav_top {
    width: 100%;
    height: 64px;
    padding: 0px 240px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 4px solid rgba(216, 216, 216, 0.2);
    box-sizing: border-box;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    ._l {
      align-items: center;
      .logo {
        width: 224px;
        height: 27px;
        margin-right: 24px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
    ._r {
      align-items: center;
      // 搜索框
      .search_wrap {
        height: 64px;
        font-weight: 400;
        font-size: 16px;
        align-items: center;
        padding: 0 23px;
        background: rgba(216, 216, 216, 0.2);
        input {
          font-family: PingFang SC, PingFang SC;
          border: none;
          background: none;
          outline: none;
          font-size: 16px;
          color: #ffffff;
        }
        ::-webkit-input-placeholder {
          color: #ffffff;
        }
        .icon {
          width: 19px;
          height: 19px;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
      .line_way {
        margin: 0 20px;
      }
    }
  }
  .nav_bot {
    padding: 15px 210px;
    padding-right: 240px;
    align-items: center;
    justify-content: space-between;
    ._l {
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 16px;
      .tab {
        // margin-right: 70px;
        // padding: 10px 35px;
        cursor: pointer;
        // background: pink;
        .tit {
          padding: 10px 35px;
        }
      }
      .on {
        border-bottom: 3px solid #7095f8;
        color: #7095f8;
        box-sizing: border-box;
      }
    }
    ._r {
      align-items: center;
      .number {
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 32px;
        align-items: center;
        .icon {
          width: 24px;
          height: 32px;
          margin: 0 12px 0 64px;
          display: flex;
          align-items: center;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    // 折叠菜单
    .menu_wrap {
      width: 170px;
      position: absolute;
      padding-top: 10px;
      // top: 140px;
      display: block;
      margin-left: -20px;
      // background: rgba(34, 39, 53, 100);
      background: rgba(0,0,0,0.8);
      .menu_li {
        font-weight: 400;
        font-size: 16px;
        color: #ffffff;
        text-align: center;
        line-height: 32px;
      }
    }
  }
}
</style>
