<!-- 联系我们 -->
<template>
  <div class="app_container">
    <!-- 导航条 -->
    <ComHeader></ComHeader>
    <main>
      <!-- 面包屑 -->
      <div class="crumbs _flex">
        <div class="text">网站首页</div>
        <i class="el-icon-arrow-right"></i>
        <div class="text">关于我们</div>
        <i class="el-icon-arrow-right"></i>
        <div class="text now">{{ crumbsName }}</div>
      </div>

      <!-- 内容详情 -->
      <div class="us_content">
        <div class="title">{{ crumbsName }}</div>

        <!-- 联系方式 -->
        <div class="content_wrap" v-if="tabId == 0">
          <div class="line_way">
            <div class="way" v-for="(v, i) in wayLi" :key="i">
              <div class="icon">
                <img :src="v.img" alt="" />
              </div>
              <div class="text">
                <div class="tit">{{ v.name }}</div>
                <div class="number">{{ v.number }}</div>
              </div>
            </div>
          </div>
        </div>

        <!-- 隐私政策 -->
        <div class="content_wrap" v-if="tabId == 1">
          <p class="top">
            畅冉企业信息咨询承诺尊重和保护您的隐私，并将此作为畅冉企业信息咨询事业活动的基础和社会责任及义务。因此在您访问畅冉企业信息咨询网站时，畅冉企业信息咨询会尽力帮助您维护互联网个人数据的控制权。
          </p>
          <div class="topic">1)对您的信息的收集</div>
          <p>
            为向您提供更好的服务，我们会要求您在进行产品试用申请、购买、售前咨询等情况下提供您的姓名(或名称)、地址及联络方式，以便我们为您提供快速准确的优质服务。
          </p>
          <div class="topic">2)对您的信息的使用</div>
          <p>
            您提供的信息将受到严格保密，畅冉企业信息咨询使用您的信息只是为了更全面地了解您的需要并为您提供更佳的服务。在一些情况下，我们可能还会用您的信息与您取得联系以进行市场调查或为您提供我们认为您可能感兴趣的市场信息。
          </p>
          <div class="topic">3)对您的信息的控制</div>
          <p>
            当您通过注册或其它方式向我们提供您的信息时，畅冉企业信息咨询不会把您的信息出售或出租给他人。您的许可总被首先保障，我们不会向任何不代表畅冉企业信息咨询的第三方透露您的信息。除非根据法律、法规或监管部门按法律执行，我们不会在未经您事先知晓或许可的情况下，以与上述内容无关的方式随意使用或分享您提供给我们的信息。我们可能会将收集到的您的信息与来自第三方的数据进行整理或合并，并用于向您网站宣传产品及/或服务。
          </p>
          <div class="topic">4)对您的信息的进入或修改</div>
          <p>
            畅冉企业信息咨询为了保护您的隐私和安全，将采取合理的措施验证身份，之后，您才能进入或修改您的信息。我们力求使您的信息保持准确，并为您提供了包括使您能够在线访问您的信息、注册数据的能力，使您可以更新或修改您的信息。
            请注意，从畅冉企业信息咨询网站到第三方网站的链接仅作为一种方便服务提供给您，如果使用这些链接，您将离开畅冉企业信息咨询网站，而第三方网站可能会收集与您有关的个人身份信息。因此，畅冉企业信息咨询站对第三方网站及其任何信息、软件或其他产品、材料、或因使用它们而可能获得的任何结果不予认可，也不作任何陈述和保证。
          </p>
          <div class="topic">5)您的信息的安全性</div>
          <p>
            畅冉企业信息咨询会认真保护您的资料以防止其丢失、被误用、受到未经授权的进入或泄漏、被篡改或毁坏。您的数据将被存放在有密码控制的服务器中，因而任何对您资料的访问都将受到限制并被严格保护。
          </p>
          <div class="topic">6)您的信息的披露</div>
          <p>
            当监管部门依照法定程序要求畅冉企业信息咨询披露您的信息时，畅冉企业信息咨询将根据监管部门要求或为公共安全之目的提供。在此情况下，畅冉企业信息咨询除免责外，您亦同时不得向畅冉企业信息咨询作出任何追究。
          </p>
          <div class="topic">7)Cookie技术的使用</div>
          <p>
            随着互联网对各项新技术的应用，畅冉企业信息咨询为访客带来了完全个性化的体验。畅冉企业信息咨询会使用Cookies的技术来分析网页的使用情形。Cookie是放置在您的电脑硬盘中的一段要求权限的文字。使用此项技术并不表示我们可自动获悉有关您的任何信息。畅冉企业信息咨询可以确定您使用的计算机类型，但使用Cookies的主要目的还在于使您在访问畅冉企业信息咨询网站时获得更佳的体验。
            同时您也拥有完全的自主权，可以将网络浏览器(MicrosoftInternet
            Explorer或Netscape Navigator)设置为通知您Cookie
            的放置请求，或者完全拒绝Cookie。您可以删除包含Cookie的文件;这些文件被保存为Internet浏览器的一部分。
          </p>
          <div class="topic">8)本声明的更改</div>
          <p>
            本声明的解释权归结于畅冉企业信息咨询。畅冉企业信息咨询有权随时更新、更改、变更或修改本隐私保护声明。请您及时关注隐私政策的动态。
            因本声明或使用畅冉企业信息咨询网站所发生的争议均受中华人民共和国法律管辖并按其解释。
          </p>
        </div>

        <!-- 法律声明 -->
        <div class="content_wrap" v-if="tabId == 2">
          <p class="top">
            本网站由畅冉企业信息咨询（西安）有限公司创建并运行维护。任何人进入本网站、阅读任何内容、进行用户注册、从本网站下载任何材料或使用本网站提供的资料，即表示同意遵守下列条款。这些条款构成畅冉企业信息咨询与您之间的协议。若不同意遵守这些条款，切勿使用本网站。八角科技保留未经通知随时更新下列条款的权利，这些更新将同样也约束您。任何时间，任何情况下，畅冉企业信息咨询均有权拒绝任何用户进入、使用本网站。
          </p>
          <div class="topic">1)版权声明</div>
          <p>
            畅冉企业信息咨询（西安）有限公司独立拥有本网站相关网页内所有资料的版权，或与本网站相关网页内的资料提供者共同拥有该网页内资料的版权，未经畅冉企业信息咨询的明确书面许可、对于本网站上的任何内容，任何人不得对其进行复制或在畅冉企业信息咨询所属的服务器上做镜像。
            本网站所载述的域名、商标、文字、视像及声音内容、图形及图像均受有关商标和著作权的法律保护。未经畅冉企业信息咨询事先以书面明确的允许,任何企业或个人不得以任何形式复制或传递。任何未经授权使用本网站的行为都将违反《中华人民共和国著作权法》和其他相关法律法规以及有关国际公约的规定。
          </p>
          <div class="topic">2)隐私保护</div>
          <p>
            任何人不得使用、利用本网站取得、窃取、盗取他人隐私，不得侵犯任何人的隐私权。本网站将对用户所提供的资料进行严格的管理及保护,并将使用相应的技术措施，防止用户的个人资料丢失、被盗用或遭篡改。如因不可抗力或计算机病毒感染、黑客攻击、恶意行为或任何第三方行为等原因，导致用户信息被破坏、泄露并受到损失的，本网站对此不承担任何责任。
          </p>
          <div class="topic">3)免责条款</div>
          <p>
            对本网站的内容，我们不提供任何形式的明示的或默示的关于内容的正确性、及时性、有效性、稳定性、可用性、不侵犯他人权利等方面的保证;不保证服务器的稳定性，不保证您任何时候均可浏览、阅读、复制、使用本网站;不保证网站内容所包含的文字、图形、材料、链接、说明、陈述或其它事项的准确性或完整性，也不保证本网站的内容不存在打印、复制及其他输入方面的错误。畅冉企业信息咨询可随时更改本网站内容无须另作通知。
            用户使用本网站服务所存在的风险将和产生的一切后果由其自己承担。在任何情况下，对于因使用本网站内容或无法进入本网站而导致的任何直接的、间接的、附带的、给第三人造成的损失(包括但不限于利润损失、信息数据丢失、财产毁坏等损失)，本网站都无须承担任何法律责任。
          </p>
          <div class="topic">4)网站使用</div>
          <p>
            请正确使用本网站，遵守本网站相关规则，不得随意篡改、修改、损毁本网站内容、页面、图片、数据库、程序等。在使用本网站或从事其它任何与本网站有关的活动中，任何人不得损害本网站和关联企业以及任何第三方的合法权益。
          </p>
          <div class="topic">5)法律适用</div>
          <p>
            对于任何侵犯本网站所享有的知识产权、任何非法使用本网站和本网站内容，以及使用和利用本网站取得、窃取、盗取他人隐私、侵犯他人隐私权的行为，和/可或从事导致本网站和/或相关企业遭受任何损失的行为，本网站和相关企业保留全额索赔和诉诸法律途径解决的权利。
            本声明的解释权及对本网站使用的解释权归结于畅冉企业信息咨询所有。
          </p>
        </div>
      </div>
    </main>
    <ComFooter></ComFooter>
  </div>
</template>

<script>
import ComHeader from "@/components/comHeader.vue";
import ComFooter from "@/components/comFooter.vue";
export default {
  components: {
    ComHeader,
    ComFooter,
  },
  data() {
    return {
      tabId: 0,
      crumbsName: "联系我们",
      wayLi: [
        {
          img: require("../assets/homeImg/contact/iphone.webp"),
          name: "手机",
          number: "15598241114",
        },
        {
          img: require("../assets/homeImg/contact/wechat.webp"),
          name: "微信",
          number: "15598241114",
        },
        {
          img: require("../assets/homeImg/contact/qq.webp"),
          name: "QQ",
          number: "895789640",
        },
        {
          img: require("../assets/homeImg/contact/email.webp"),
          name: "E-mail",
          number: "a15598241114@gmail.com",
        },
      ],
    };
  },
  watch: {
    $route: {
      handler(newV, _oldV) {
        if (newV.query.id) {
          this.tabId = newV.query.id;
          if (newV.query.id == 0) {
            this.crumbsName = "联系方式";
          } else if (newV.query.id == 1) {
            this.crumbsName = "隐私政策";
          } else if (newV.query.id == 2) {
            this.crumbsName = "法律声明";
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "../style/comStyle.scss";
.app_container {
  ::v-deep .nav_wrap {
    position: static;
    background: #222735;
    .nav_bot {
      padding-bottom: 2px;
    }
  }
  main {
    background: #f0f0f0;
    padding: 0 126px;
    // 面包屑
    .crumbs {
      padding: 53px 80px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 27px;
      color: #878686;

      .el-icon-arrow-right {
        margin: 0 13px;
      }

      .now {
        font-weight: 600;
        font-size: 27px;
        color: #7095f8;
      }
    }

    // 内容详情
    .us_content {
      padding: 53px 68px;
      background: #ffffff;
      .title {
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 40px;
        color: #151618;
        text-align: center;
        margin-bottom: 32px;
      }
      .content_wrap {
        .topic {
          font-family: PingFang SC-Semibold;
          font-weight: 600;
          font-size: 25px;
          color: #525252;
        }
        p {
          font-family: PingFang SC-Regular;
          font-weight: 400;
          font-size: 25px;
          color: #525252;
          line-height: 36px;
        }
        .top {
          margin-bottom: 28px;
        }

        // 联系方式
        .line_way {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .way {
            min-width: 320px;
            padding: 57px 0 82px 0;
            background: #f7f9ff;
            .icon {
              width: 120px;
              height: 120px;
              margin: 0 auto;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .text {
              font-family: PingFang SC, PingFang SC;
              color: #525252;
              text-align: center;
            }
            .tit {
              font-weight: 600;
              font-size: 28px;
              margin-top: 36px;
              margin-bottom: 18px;
            }
            .number {
              font-weight: 400;
              font-size: 25px;
            }
          }
        }
      }
    }
  }
}
</style>
